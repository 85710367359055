import { LAST_ORGANIZATION_ID_KEY } from '../context/AuthContext'
import {
  ActionExercises,
  Checkin,
  Connection,
  Exercise,
  Goal,
  OrganizationCourse,
  OrganizationRole,
  Plan,
  Course,
  PromoCode,
  Space,
  User,
  Organization,
  CoursePrestige,
  Achievement,
  EventTag,
  Event,
  Post,
  Comment,
  Reaction,
  ReactionType,
  OrganizationPlaylist,
  SpaceRole,
  StripeSubscription,
  InvitePerson
} from '../types'
import { config } from './config'

const makeHeaders = () => {
  const organizationId = localStorage.getItem(LAST_ORGANIZATION_ID_KEY) ?? '0'

  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'X-Organization-Id': organizationId
  }
}

const apiGet = async <T>(path: string): Promise<T> => {
  const res = await fetch(`${config.API_HOST}${path}`, {
    method: 'GET',
    headers: makeHeaders(),
    credentials: 'include'
  })
  return res.json()
}

const apiPut = async <T>(path: string, body?: any): Promise<T> => {
  const res = await fetch(`${config.API_HOST}${path}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: makeHeaders(),
    credentials: 'include'
  })
  const json = await res.json()
  if (!res.ok) {
    throw new Error(json.error || 'Unknown error')
  }
  return json
}

const apiPost = async <T>(path: string, body?: any): Promise<T> => {
  const res = await fetch(`${config.API_HOST}${path}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: makeHeaders(),
    credentials: 'include'
  })
  const json = await res.json()
  if (!res.ok) {
    throw new Error(json.error || 'Unknown error')
  }
  return json
}

const apiDelete = async (path: string, body?: any) => {
  await fetch(`${config.API_HOST}${path}`, {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: makeHeaders(),
    credentials: 'include'
  })
}

const apiDeleteWithResponse = async (path: string, body?: any) => {
  const res = await fetch(`${config.API_HOST}${path}`, {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: makeHeaders(),
    credentials: 'include'
  })
  return res.json()
}

const getLastOrganizationId = (): number => {
  return parseInt(localStorage.getItem(LAST_ORGANIZATION_ID_KEY) ?? '0')
}

export async function getUser (): Promise<User> {
  const lastOrganizationId = getLastOrganizationId()
  const validOrganizationId =
    lastOrganizationId !== 0 ? lastOrganizationId : null

  const user = await apiGet<User>(
    `/user${
      validOrganizationId !== null
        ? `?organizationId=${validOrganizationId}`
        : ''
    }`
  )
  const activeOrganizationRoles =
    user?.organizationRoles?.filter((role) => role.status !== 'INACTIVE') ?? []

  if (
    activeOrganizationRoles.find(
      (role) => role.organization?.id === lastOrganizationId
    )
  ) {
    return {
      ...user,
      currentOrganizationId: lastOrganizationId
    }
  }

  return {
    ...user,
    currentOrganizationId: activeOrganizationRoles[0]?.organization?.id || null
  }
}

export async function putUser (user: User) {
  const newUser = await apiPut<User>('/user', user)
  return {
    ...newUser,
    currentOrganizationId:
      getLastOrganizationId() || user?.organizationRoles?.[0]?.organization?.id
  }
}

export async function getExerciseListBySpace ({ organizationRoleId, spaceId, userId }: { organizationRoleId: number, spaceId?: number, userId?: number }) {
  return await apiGet<Exercise[]>(
    `/exercise_progress?organizationRoleId=${organizationRoleId}${
      spaceId ? `&spaceId=${spaceId}` : ''
    }${
      userId ? `&userId=${userId}` : ''
    }`
  )
}

export async function getExerciseListByUser ({ organizationRoleId, userId }) {
  return await apiGet<Exercise[]>(
    `/exercise_progress?organizationRoleId=${organizationRoleId}&userId=${userId}`
  )
}

export async function getSpaces ({ filter, isArchived }) {
  const queryString =
    filter && isArchived != null
      ? `?filter=${filter}&isArchived=${isArchived}`
      : filter
        ? `?filter=${filter}`
        : isArchived != null
          ? `?isArchived=${isArchived}`
          : ''
  return await apiGet<Space[]>(`/spaces${queryString}`)
}

export async function postSpaces (space) {
  return await apiPost('/spaces', space)
}

export async function putSpaces (space) {
  const removedConnectionSpace = { ...space, connections: null }
  const putSpaceResponse = await apiPut<Space>(
    `/spaces/${space.id}`,
    removedConnectionSpace
  )
  const connections = (
    await apiGet<Connection[]>(
      `/connections${space.id ? `?spaceId=${space.id}` : ''}`
    )
  ).sort((a, b) => b.id - a.id)
  return { ...putSpaceResponse, connections }
}

export async function getSpace (id) {
  const [space, connections] = await Promise.all([
    apiGet<Space>(`/spaces/${id}`),
    apiGet<Connection[]>(`/connections${id ? `?spaceId=${id}` : ''}`)
  ])
  return { ...space, connections }
}

export async function searchPeopleByEmail ({ email, spaceId }) {
  const results = await apiGet<OrganizationRole[]>(
    `/organization_roles/?email=${email}&spaceId=${spaceId}`
  )
  return !!results
}

export async function getPeople () {
  return await apiGet<OrganizationRole[]>('/organization_roles')
}

export async function getPeopleGallery () {
  return await apiGet<OrganizationRole[]>('/people_gallery')
}

export async function getPeopleBySpace (spaceId) {
  return await apiGet<OrganizationRole[]>(
    `/organization_roles${spaceId ? `?spaceId=${spaceId}` : ''}`
  )
}

export async function getAchievements (organizationRoleId : number) {
  return await apiGet<Achievement[]>(`/organization_roles/${organizationRoleId}/achievements`)
}

export async function updatePerson (person) {
  return await apiPut(`/organization_roles/${person?.id}`, person)
}

export async function addPeople ({ person, emails }) {
  return await apiPost('/organization_roles', { person, emails })
}

export async function resendInvite (inviteCode: string) {
  return await apiPost(`/organization_roles/resend_invite/${inviteCode}`)
}

export async function sendInvite (person: OrganizationRole) {
  return await apiPost(`/organization_roles/${person.id}/invite`, person)
}

export async function getOrganizationRole ({ id, spaceId }) {
  return await apiGet<OrganizationRole>(
    `/organization_roles/${id}${spaceId ? `?spaceId=${spaceId}` : ''}`
  )
}

export async function putPersonStatus ({ organizationRoleId, status }) {
  return await apiPut(`/organization_roles/${organizationRoleId}:id/status`, {
    organizationRoleId,
    status
  })
}

export async function putPeopleStatus ({ organizationRoles, status }) {
  return await apiPut('/organization_roles/status', {
    organizationRoles,
    status
  })
}

export async function getConnection (connectionUid) {
  return await apiGet<Connection>(`/connections/${connectionUid}`)
}

export async function endConnection (connectionUid) {
  return await apiPut(`/connections/${connectionUid}/end`)
}

export async function deleteConnection (connectionUid) {
  return await apiDeleteWithResponse(`/connections/${connectionUid}`)
}

export async function startConnection ({
  spaceId,
  location,
  startTime,
  title,
  connectionTags,
  connectionType,
  postEvent
}: {
  spaceId: number | undefined;
  location: string;
  startTime: Date;
  title: string;
  connectionTags: string[];
  connectionType: string;
  postEvent?: boolean;
}) {
  return await apiPost('/connections', {
    spaceId,
    location,
    startTime,
    title,
    connectionTags,
    connectionType,
    postEvent
  })
}

export async function updateConnection ({
  connectionUid,
  startTime,
  location,
  title,
  connectionTags,
  connectionType,
  postEvent
}: {
  connectionUid: string | null;
  startTime: Date;
  location: string;
  title: string;
  connectionTags: string[];
  connectionType: string;
  postEvent?: boolean;
}) {
  return await apiPut(`/connections/${connectionUid}`, {
    startTime,
    location,
    title,
    connectionTags,
    connectionType,
    postEvent
  })
}

export async function addOrganizationCourse (body) {
  return await apiPost('/organization_courses', body)
}

export async function getOrganizationPlaylists () {
  return await apiGet<OrganizationPlaylist[]>('/organization_playlists')
}

export async function getOrganizationUserCourses (organizationRoleId) {
  return await apiGet<Course[]>(
    `/user_courses?organizationRoleId=${organizationRoleId}`
  )
}

export async function addContactNote ({
  contactId,
  note
}) {
  return await apiPost(
    `/contacts/${contactId}/note`,
    { note }
  )
}

export async function editContactNote ({
  contactNoteId,
  note
}) {
  return await apiPut(
    `/contact_note/${contactNoteId}`,
    { note }
  )
}

export async function deleteContactNote ({ contactNoteId }) {
  return await apiDeleteWithResponse(`/contact_note/${contactNoteId}`)
}

export async function postExerciseProgress ({
  spaceId,
  exerciseId,
  connectionId,
  userId,
  organizationRoleId
}) {
  return await apiPost('/exercise_progress', {
    exerciseId,
    connectionId,
    userId,
    spaceId,
    organizationRoleId
  })
}

export async function deleteExerciseProgress ({
  exerciseProgressId: id,
  spaceId,
  userId,
  organizationRoleId
}) {
  return await apiDelete(`/exercise_progress/${id}}`, {
    spaceId,
    userId,
    organizationRoleId
  })
}

export async function getUsernameSearchResults ({ username, spaceId }) {
  return await apiGet<User[]>(
    `/users/search/?username=${username}${spaceId ? `&spaceId=${spaceId}` : ''}`
  )
}

export async function getOrganizationReports (organizationId) {
  return await apiGet(`/communities/${organizationId}/reports`)
}

export async function getOrganizationDailyReports (organizationId) {
  return await apiGet(`/communities/${organizationId}/reports_daily`)
}

export async function getWeeklyReport ({
  organizationId,
  startWeekLocal,
  endWeekLocal
}) {
  return await apiGet(
    `/communities/${organizationId}/weekly_report?weekstart=${startWeekLocal}&weekend=${endWeekLocal}`
  )
}

export async function getDailyReport ({ startDay, nextDay }) {
  return await apiGet(`/checkin/progress?start=${startDay}&next=${nextDay}`)
}

export async function getCourse (uid) {
  return await apiGet<Course>(`/courses/${uid}`)
}

export async function getPublicCourses () {
  return await apiGet<Course[]>('/course')
}

export async function getPlans () {
  return await apiGet<Plan>('/plans')
}

export async function createOrganizationCourse ({ organizationId, courseUid }) {
  return await apiPost<OrganizationCourse>(
    `/communities/${organizationId}/courses`,
    { courseUid }
  )
}

export async function createOrganization ({ name, courseUid }) {
  return await apiPost<Organization>('/communities', {
    name,
    courseUid
  })
}

export type updateOrganizationRequest = {
  organizationId: number;
  name: string;
  domain?: string;
  logoUrl?: string;
  memberSpaceCreation: boolean;
  dailyCheckin?: boolean;
  dailyCheckinDaysOfWeek?: string;
  editConnection?: boolean;
  defaultSpace: boolean;
  shareContactData: boolean;
  membersCanInvite: boolean;
};

export async function updateOrganization ({
  organizationId,
  name,
  domain,
  logoUrl,
  memberSpaceCreation,
  dailyCheckin,
  dailyCheckinDaysOfWeek,
  editConnection,
  defaultSpace,
  shareContactData,
  membersCanInvite
}: updateOrganizationRequest) {
  return await apiPut(`/communities/${organizationId}`, {
    name,
    logoUrl,
    memberSpaceCreation,
    dailyCheckin,
    dailyCheckinDaysOfWeek,
    editConnection,
    domain,
    defaultSpace,
    shareContactData,
    membersCanInvite
  })
}

export async function updateSpaceRoles (request) {
  return await apiPut(
    `/organization_roles/${request?.organizationRoleId}/space_roles`,
    request
  )
}

export async function generateAvatarUploadUrlForOrganization () {
  return await apiPost<{ url: string, uploadUrl: string }>('/organization/avatar')
}

export async function generateAvatarUploadUrlForUser () {
  return await apiPost<{ url: string, uploadUrl: string }>('/user/avatar')
}

export async function getOrganizationSubscription (organizationId): Promise<StripeSubscription> {
  return await apiGet(`/communities/${organizationId}/subscription`)
}

export async function putPromoCode (body) {
  return await apiPut(`/communities/${body.organizationId}/promo_code`, body)
}

export async function checkIsValidPromoCode ({ promoCode, organizationId }) {
  return await apiGet<PromoCode[]>(
    `/communities/${organizationId}/promo_code/?code=${promoCode}`
  )
}

export async function unfreezeOrganizationAndUpdatePaymentMethod ({
  organizationId,
  paymentMethodId,
  customerId
}) {
  return await apiPut(`/communities/${organizationId}/update_payment`, {
    paymentMethodId,
    customerId
  })
}

export async function getOrganizationGoals (organizationId) {
  return await apiGet<Goal[]>(`/communities/${organizationId}/goals`)
}

export async function createOrganizationGoal (title) {
  return await apiPost('/goal', { title })
}

export async function updateOrganizationGoal ({ goalId, title }) {
  return await apiPut(`/goal/${goalId}`, { title })
}

export async function deleteOrganizationGoal ({ goalId }) {
  return await apiDelete(`/goal/${goalId}`)
}

export async function getUserActions ({ organizationRoleId, spaceId }) {
  return await apiGet<ActionExercises>(
    `/actions/?organizationRoleId=${organizationRoleId}${
      spaceId ? `&spaceId=${spaceId}` : ''
    }`
  )
}

export async function createAction (body) {
  return await apiPost('/actions', body)
}

export async function updateAction ({
  id,
  status,
  description,
  organizationRoleId,
  dueBy,
  spaceId
}) {
  return await apiPut(`/actions/${id}`, {
    status,
    description,
    organizationRoleId,
    dueBy,
    spaceId
  })
}

export async function deleteAction ({
  id,
  organizationRoleId,
  uid,
  spaceId
}: {
  id: number;
  organizationRoleId: number;
  uid?: string;
  spaceId?: number;
}) {
  return await apiDelete(`/actions/${id}`, {
    organizationRoleId,
    uid,
    spaceId
  })
}

export async function updateConnectionAttendance ({
  connectionId,
  uid,
  organizationRoleId,
  spaceId
}) {
  return await apiPost('/connection_attendance', {
    connectionId,
    uid,
    organizationRoleId,
    spaceId
  })
}

export async function deleteConnectionAttendance ({
  connectionId,
  uid,
  organizationRoleId,
  spaceId
}) {
  return await apiDelete('/connection_attendance', {
    connectionId,
    uid,
    organizationRoleId,
    spaceId
  })
}

export async function getOrganizationRoleDailyCheckins () {
  return apiGet<Checkin[]>('/checkin')
}

export async function createDailyCheckin (checkinResponses) {
  return await apiPost('/checkin/progress', checkinResponses)
}

export async function createOrUpdateCheckin (checkinResponses) {
  return await apiPut('/checkin/progress', checkinResponses)
}

export async function putDailycheckins (checkins) {
  return await apiPut('/checkin', checkins)
}

export async function getOrganizationRoleWithDailyCheckin () {
  return await apiGet<OrganizationRole[]>('/checkin/access')
}

export async function updateOrganizationRoleDailyCheckinAccess (
  organizationRoles
) {
  return await apiPut('/checkin/access', { organizationRoles })
}

export async function updateOrganizationPlaylist ({
  id,
  courseId,
  access,
  enabled,
  requiresReview,
  actionsApproved
}) {
  return await apiPut(`/organization_playlists/${id}`, {
    courseId,
    access,
    enabled,
    requiresReview,
    actionsApproved
  })
}

export async function updateOrganizationRoleOrganizationCourseAccess ({
  organizationId,
  body
}) {
  return await apiPut(
    `/communities/${organizationId}/courses/organization_role`,
    body
  )
}

export async function getCoursePresitge (organizationRoleId: number) {
  return await apiGet<CoursePrestige[]>(
    `/course_prestige?organizationRoleId=${organizationRoleId}`
  )
}

export async function putCoursePresitge ({
  organizationRoleId,
  courseVersionId
}) {
  return await apiPut('/course_prestige', {
    organizationRoleId,
    courseVersionId
  })
}

export async function getFreedomCheckConnection (id) {
  return await apiGet(`/freedom_check_connection/${id}`)
}

export async function getExercisesActionsForApproval (courseVersionId) {
  return await apiGet(`/exercises/action_approval/${courseVersionId}`)
}

export async function claimInviteCode (inviteCode: string) {
  return await apiPost<{ organizationId?: number }>(`/invite/${inviteCode}/claim`)
}

export async function getOrganizationTags () {
  return await apiGet('/organziation_tags')
}

export async function putContactTag ({ contactId, names }) {
  return await apiPut(`/contacts/${contactId}/tags`, { names })
}

export async function getEvents () {
  return await apiGet<Event[]>('/connections_display')
}

export async function getEventTags () {
  return await apiGet<EventTag[]>('/connection_tags')
}

export async function joinEvent ({ eventUid }) {
  return await apiPut(`/connection/${eventUid}/join`)
}

export async function getPosts (cursor: number | null = null) {
  const limit = 20
  return await apiGet<{ posts: Post[], liveEvents: string[], pagination: { nextCursor: number | null, hasNextPage: boolean } }>(
    `/posts?${cursor ? `cursor=${cursor}&` : ''}limit=${limit}`
  )
}

export async function getPostsBySpace (spaceId: number, cursor: number | null = null) {
  const limit = 20
  return await apiGet<{ posts: Post[], liveEvents: string[], pagination: { nextCursor: number | null, hasNextPage: boolean } }>(
    `/space/${spaceId}/posts?${cursor ? `cursor=${cursor}&` : ''}limit=${limit}`
  )
}

export async function createPost ({ content, spaceId }: { content: string, spaceId?: number | null }) {
  return await apiPost<Post>('/posts', { content, spaceId: spaceId ?? undefined })
}

export async function updatePost (id: number, content: string) {
  return await apiPut<Post>(`/posts/${id}`, { content })
}

export async function deletePost (id: number) {
  return await apiDeleteWithResponse(`/posts/${id}`)
}

export async function reactToPost (id: number, type: ReactionType) {
  return await apiPut<{ id: number, spaceId?: number, reactionId?: number, newReaction?: any }>(`/posts/${id}/reactions`, { type })
}

export async function getPostComments (id: number) {
  return await apiGet<Comment[]>(`/posts/${id}/comments`)
}

export async function createComment (content: string, postId: number, parentCommentId?: number) {
  return await apiPost<Comment>('/comments', { content, postId, parentCommentId })
}

export async function updateComment (id: number, content: string) {
  return await apiPut<Comment>(`/comments/${id}`, { content })
}

export async function deleteComment (id: number) {
  return await apiDeleteWithResponse(`/comments/${id}`)
}

export async function reactToComment (id: number, type: ReactionType) {
  return await apiPut<Reaction>(`/comments/${id}/reactions`, { type })
}

export async function getPlaylist ({ spaceId, type }: { spaceId: number, type: 'courses' | 'media' }) {
  return await apiGet<any>(`/spaces/${spaceId}/playlist?type=${type}`)
}

export async function getActiveCourseVersionId (organizationRoleId: number, spaceId: number) {
  return await apiGet<{ activeCourseVersionId: number, courses: { id: number, name: string }[] }>(`/exercise_progress/active_course_version_id?organizationRoleId=${organizationRoleId}&spaceId=${spaceId}`)
}

export async function updateSpaceSpaceRoles (spaceId: number, spaceRoles: Partial<SpaceRole>[]) {
  return await apiPut(`/spaces/${spaceId}/space-roles`, spaceRoles)
}

export async function validateOrganizationInviteCode (inviteCode: string) {
  return await apiGet<{ valid: boolean, organization: { name: string, logoUrl: string, memberCount: number } }>(`/community/invite/${inviteCode}/validate`)
}

export async function invitePeopleOrCreateContacts ({ people, sendEmails }: { people: InvitePerson[], sendEmails: boolean }) {
  return await apiPost('/organization_roles/invite_people_or_create_contacts', { people, sendEmails })
}
