import {
  getCurrentOrganizationRole,
  sortSpaceRolesByRoleAndFirstName
} from '../../shared/utilities'
import { useAuthContext } from '../../context/AuthContext'
import {
  useUpdateConnectionAttendanceMutation,
  useDeleteConnectionAttendanceMutation
} from '../../shared/queryHooks'
import ConnectionUserItem, {
  FormattedExerciseProgress
} from '../ConnectionUserItem'
import './index.css'
import { Connection, OrganizationRole, Space } from '../../types'
import { useState } from 'react'

interface AttendanceProps {
  space?: Space | null;
  connection: Connection;
  reviewing?: boolean;
  editAttendance?: boolean;
}

function Attendance ({
  space,
  connection,
  reviewing = false,
  editAttendance = false
}: AttendanceProps) {
  const { currentUser } = useAuthContext()
  const [search, setSearch] = useState('')
  const updateAttendance = useUpdateConnectionAttendanceMutation()
  const deleteAttendance = useDeleteConnectionAttendanceMutation()
  const currentUserOrgRole = getCurrentOrganizationRole(currentUser)
  const isCurrentUserInSpace =
    currentUserOrgRole?.spaceRoles?.some(
      (role) => role.spaceId === space?.id
    ) ?? false
  const exerciseProgress =
    connection?.connectionAttendance?.map(
      (attendance) =>
        ({
          organizationRoleId: attendance?.organizationRoleId,
          exerciseProgress: attendance?.exerciseProgress
        } as FormattedExerciseProgress)
    ) ?? []
  const attendance =
    connection?.connectionAttendance?.map(
      (attendance) => attendance?.organizationRoleId ?? 0
    ) ?? []
  const connectionReviews = connection?.space?.organization?.checkins ?? []

  const addActiveUserToSpace = (isActiveUserInSpace: boolean) => {
    if (!isActiveUserInSpace && !reviewing) {
      const newSpaceList = space?.spaceRoles?.map((space) => space) ?? []

      const orgRole: OrganizationRole = {
        ...currentUserOrgRole,
        user: currentUser
      } as OrganizationRole
      newSpaceList?.push({
        id: 0,
        spaceId: space?.id ?? 0,
        title: 'MEMBER',
        organizationRoleId: currentUserOrgRole?.id ?? 0,
        organizationRole: orgRole
      })
      return newSpaceList
    }
    if (reviewing) {
      const displayAttendanceOnlySpaceList = space?.spaceRoles?.filter((role) =>
        attendance?.includes(role?.organizationRoleId ?? 0)
      )
      return displayAttendanceOnlySpaceList
    }
    return space?.spaceRoles
  }

  const saveAttendance = async (organizationRoleId) => {
    await updateAttendance.mutateAsync({
      connectionId: connection?.id,
      uid: connection?.uid,
      organizationRoleId,
      spaceId: space?.id
    })
  }

  const removeAttendance = async (organizationRoleId) => {
    await deleteAttendance.mutateAsync({
      connectionId: connection?.id,
      uid: connection?.uid,
      organizationRoleId,
      spaceId: space?.id
    })
  }

  const searchAttendance = (role) => {
    const fullName = `${role?.organizationRole?.contact?.firstName} ${role?.organizationRole?.contact?.lastName}`
    return fullName.toLowerCase().includes(search.toLowerCase())
  }

  const isConnectionCompleted = !!connection?.endTime

  const attendanceList =
    addActiveUserToSpace(isCurrentUserInSpace)?.sort((a, b) => {
      if (isConnectionCompleted) {
        const aAttended = attendance.includes(a?.organizationRoleId ?? 0)
        const bAttended = attendance.includes(b?.organizationRoleId ?? 0)
        if (aAttended !== bAttended) {
          return bAttended ? 1 : -1
        }
      }
      return sortSpaceRolesByRoleAndFirstName(a, b)
    }) ?? []

  return (
    <div className="attendance-content">
      {attendanceList?.length >= 10 && (
        <div className="search-bar-container">
          <input
            type="text"
            placeholder="Search..."
            className="search-bar"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      )}
      {attendanceList?.filter(searchAttendance)?.map((spaceRole) => (
        <ConnectionUserItem
          key={spaceRole?.organizationRoleId}
          id={spaceRole?.organizationRoleId ?? 0}
          firstName={spaceRole?.organizationRole?.contact?.firstName ?? ''}
          lastName={spaceRole?.organizationRole?.contact?.lastName ?? ''}
          courseVersion={connection?.space?.courseVersion?.version ?? 0}
          attendance={attendance}
          saveAttendance={saveAttendance}
          removeAttendance={removeAttendance}
          avatar={spaceRole?.organizationRole?.user?.avatar ?? ''}
          readOnly={!!connection?.endTime}
          connectionReviews={connectionReviews}
          connectionId={connection?.id}
          reviewing={reviewing}
          spaceRole={spaceRole?.title}
          editAttendance={editAttendance}
          formattedExerciseProgress={exerciseProgress?.filter(
            (progress) =>
              progress?.organizationRoleId === spaceRole?.organizationRoleId
          )}
        />
      ))}
    </div>
  )
}

export default Attendance
