import { useState } from 'react'
import Card from '../Card'
import './index.css'
import ManageUserSpaceRoles from '../ManageUserSpaceRoles'
import { OrganizationRole, Space, SpaceRole } from '../../types'
import { DisplayState } from '../../shared/utilities'

interface ManageSpaceCardProps {
  space: Space
  setUserSpaces: DisplayState<SpaceRole[]>
  userSpaces: SpaceRole[]
  organizationRoleId: number
  person: OrganizationRole
}

function ManageSpaceCard ({ space, setUserSpaces, userSpaces, organizationRoleId, person }: ManageSpaceCardProps) {
  const currentRole = userSpaces?.find((role) => role.spaceId === space.id) ?? {
    id: 0,
    spaceId: space.id,
    organizationRoleId,
    title: 'MEMBER'
  }
  const [spaceRole, setSpaceRole] = useState<SpaceRole>(currentRole)

  function handleToggle (spaceId) {
    if (userSpaces?.some((role) => role.spaceId === parseInt(spaceId))) {
      const newSpaceRoleList = userSpaces.filter((role) => role.spaceId !== parseInt(spaceId))
      setUserSpaces(newSpaceRoleList)
    } else {
      setUserSpaces((userSpaces) => [...userSpaces, spaceRole])
    }
  }

  function selectRole (title) {
    setSpaceRole((previousRole) => ({ ...previousRole, title }))
    setUserSpaces((previousRoles) =>
      previousRoles.map((role) => (role.spaceId === space.id ? { ...role, title } : role))
    )
  }
  const isSpaceMember = userSpaces?.some((spaceRole) => spaceRole?.spaceId === space?.id)
  const hasSpaceCourse = !!space?.courseVersionId
  const courseName = hasSpaceCourse ? space?.courseVersion?.course?.name : ''
  const hasSpaceRoleLimit: boolean = person?.title === 'GUEST' && userSpaces.length > 0

  const rightColumnContent = () => {
    if (isSpaceMember) {
      return <ManageUserSpaceRoles selectRole={selectRole} spaceRole={spaceRole} person={person} />
    } else {
      return <></>
    }
  }

  return (
    <>
      <div className="space-card-container-modal" data-testid={`space-card-space-${space.id}`}>
        <label className="inner-space-card-container-modal">
          {!(hasSpaceRoleLimit)
            ? (
            <input
              value={space.id}
              onChange={(e) => handleToggle(e.target.value)}
              type="checkbox"
              className={'space-checkbox'}
              checked={isSpaceMember}
              data-testid={`space-id-${space.id}`}
            />
              )
            : (
            <div className="space-checkbox-spacer"></div>
              )}
          <div className="card-wrapper">
            <Card name={space.name} peopleCount={space?.spaceRoles?.length ?? 0} courseName={courseName} />
          </div>
        </label>
        {rightColumnContent()}
      </div>
    </>
  )
}

export default ManageSpaceCard
